window.volverInicio = function () {
		var identificadorCarrito = document.getElementById("identificadorCarrito").value;
		window.location = '/?cart=' + identificadorCarrito;
};

window.despliegaCategorias = function() {
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/categoriasInventario?widhtg=' + widhtg + "&heigth=" + heigth;
};

window.despliegaCategoriasUsuario = function() {
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/despliegaInventarioSucursal?widhtg=' + widhtg + "&heigth=" + heigth;
};

window.listadoCategorias = function() {
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/listadoCategoriasAdmin?widhtg=' + widhtg + "&heigth=" + heigth;
};

window.categoriasUsuario = function() {
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	var identificadorCarrito = document.getElementById('identificadorCarrito').value;
	window.location = '/listadoCategorias?widhtg=' + widhtg + "&heigth=" + heigth + "&cart=" + identificadorCarrito;
};

window.categoriasListado = function() {
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	var identificadorCarrito = document.getElementById('identificadorCarrito').value;
	window.location = '/categoriasListado?widhtg=' + widhtg + "&heigth=" + heigth + "&cart=" + identificadorCarrito;
};